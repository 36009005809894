.data-points-group-list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 29px;
}

@media only screen and (max-width: 1600px) {
  .data-points-group-list {
    gap: 20px;
  }
}
