/*
  we want the site ID to be searchable when rendered within drop-down,
  but we also want to wrap it within brackets to make it visually distinct -
  so we wrap it in brackets using CSS and not in the text itself...
*/

.soround-with-parenthesis::before {
  content: "(";
}

.soround-with-parenthesis::after {
  content: ")";
}
