.in-override {
  width: 16px;
  height: 16px;
  background-color: var(--error-color);
  border-radius: 50%;
  position: relative;
  top: -5px;
  right: 16px;
  animation: flash 0.7s infinite;
  border: 0px;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
