.app-bar {
  box-shadow: none;
  background-color: transparent;
  color: var(--blue-color-primary);
}

.tool-bar {
  margin-left: var(--logo-margin-left-right);
  margin-right: var(--logo-margin-left-right);
  padding: 0;
  width: calc(100% - 2 * var(--logo-margin-left-right));
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.left-components {
  justify-content: flex-start;
}

.right-components {
  width: 100%;
  justify-content: flex-end;
}

.logo {
  margin: 0;
}

.sites-dropdown {
  margin-left: 8px;
  white-space: nowrap;
}

.data-points {
  padding: 0 1em;
}

.diffuse-optimization {
  margin: 0 1em;
}

.system-status {
  justify-content: center;
}

.emergency {
  margin: 0 1em;
}

.time-and-user {
  width: 230px;
  white-space: nowrap;
  justify-content: space-between;
}

.scheduled-command {
  margin: 0 1em;
}

@media screen and (max-width: 1600px) {
  .time-and-user {
    width: 170px;
  }

  .scheduled-command {
    margin: 0 0.3em;
  }

  .emergency {
    margin: 0 0.3em;
  }

  .diffuse-optimization {
    margin: 0 0.3em;
  }
}
