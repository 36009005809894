.system-status-points-container {
  background-color: var(--table-on-hover-color);
  border-radius: var(--border-radius-button);
  height: 50px;
  padding: 0 0 0 16px;
}

.system-status-points-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  list-style-type: none;
  gap: 30px;
}

@media screen and (max-width: 1600px) {
  .system-status-points-container {
    height: 35px;
    padding: 0 0 0 10px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
