.status-points-list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  align-items: center;
}
.status-mark {
  display: flex;
  margin-top: -72px;
}
.status-icon-and-mark {
  margin-left: -30px;
}
@media screen and (max-width: 1600px) {
  .status-mark {
    margin-top: -51px;
  }
  .status-icon-and-mark {
    margin-left: -20px;
  }
}
