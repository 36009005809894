.emergency-btn {
  white-space: nowrap;
  height: 48px;
  width: 84px;
  background: #f17a7a;
  text-transform: none;
  border: 2px solid white;
  letter-spacing: 0.05em;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 20px 8px 20px;
  gap: 10px;
  border-radius: 33.26px;
}

@media screen and (max-width: 1600px) {
  .emergency-btn {
    height: 35px;
  }
}

.emergency-btn:hover {
  background: #df5f5f;
  color: white;
  border: 1px solid #df5f5f;
}

.emergency-true {
  color: var(--white);
  display: inline-flex;
  background-color: #f17a7a;
  border: 2px double #ffffff;
  box-shadow: 2px 9px 7px -4px #0000002e inset;
}

.emergency-false {
  color: var(--white);
  display: inline-flex;
}

.emergency-btn-div {
  display: flex;
  flex-direction: column;
  padding: 1% 1% 0% 0.5%;
  margin-bottom: 1%;
  align-items: center;
}

.disabled {
  cursor: not-allowed;
  background-color: var(--disable-color);
  border: none !important;
  color: var(--white) !important;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 0px;
}

.button-container-cancel-emergency {
  margin-top: 7px;
}
